@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Titan+One&display=swap");
.contactform {
  padding: 1% 15% 10% 15%;
}

.logo {
  animation-name: wobble;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

.updatecontainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  margin-left: 30%;
  margin-right: 30%;
}

.incentivecard {
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 1%;
  margin-bottom: 10px;
}

.monthlyincentiveadmin {
  display: flex;
  gap: 1%;
  padding: 1%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
}

@media only screen and (max-width: 600px) {
  .monthlyincentiveadmin {
    flex-direction: column;
    justify-content: center;
  }
  .incentivecard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    row-gap: 20px;
    margin-bottom: 10px;
  }
  .updatecontainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    margin-left: 2%;
    margin-right: 2%;
  }
}

.buttons {
  animation-name: zoomin;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes wobble {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes zoomin {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  /* 50% { transform: scale(1); } */
  /* 75% { transform: scale(1.2); } */
  100% {
    transform: scale(1);
  }
}
